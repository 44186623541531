import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Tags from "./tags";

const PostList = ({ posts }) => {
	const PostList = posts.map(({ frontmatter, fields, excerpt, timeToRead }) => {
		const { title, tags, date, description } = frontmatter;
		const { slug } = fields;

		return (
			<PostListItem
				key={slug}
				tags={tags}
				title={title}
				date={date}
				slug={slug}
				timeToRead={timeToRead}
				description={description}
				excerpt={excerpt}
			/>
		);
	});

	return <StyledPostList>{PostList}</StyledPostList>;
};

export default PostList;

const PostListItem = ({
	title,
	date,
	timeToRead,
	tags,
	excerpt,
	description,
	slug,
}) => {
	return (
		<StyledPostListItem>
			<Tags tags={tags} />

			<PostListTitle>
				<Link to={slug}>{title}</Link>
			</PostListTitle>
			<PostListExcerpt
				dangerouslySetInnerHTML={{
					__html: description || excerpt,
				}}
			/>
			<PostListMeta>
				<span>{date}</span>

				<span>{timeToRead} mins</span>
			</PostListMeta>
		</StyledPostListItem>
	);
};

const StyledPostList = styled.ul`
	padding: 0;
	list-style: none;
	display: grid;
	justify-items: center;
	grid-gap: var(--size-600);
	grid-template-columns: repeat(auto-fit, minmax(35ch, 1fr));

	@media screen and (max-width: 500px) {
		& {
			display: block;
		}
	}
`;

const StyledPostListItem = styled.li`
	display: flex;
	padding: 1.5rem;
	border-radius: 8px;
	position: relative;
	flex-direction: column;
	transition: all 0.3s ease-out;

	body.light-mode & {
		backdrop-filter: blur(10px);
		border: 1px solid rgba(255, 255, 255, 0.5);
		border-radius: 20px;
		background-color: rgba(200, 200, 200, 0.3);
	}

	body.light-mode &:hover {
		background-color: rgba(255, 255, 255, 0.5);
	}

	body.dark-mode & {
		backdrop-filter: blur(10px);
		border: 1px solid rgba(175, 175, 175, 0.5);
		border-radius: 20px;
		background-color: rgba(25, 25, 25, 0.6);
	}

	body.dark-mode &:hover {
		background-color: rgba(60, 60, 60, 0.5);
	}

	@media screen and (max-width: 500px) {
		& {
			margin-top: var(--size-600);
		}
	}
`;

const PostListTitle = styled.h2`
	line-height: 1.2;
	margin-top: 1rem;
	margin-bottom: 1rem;
	text-transform: none;
	font-size: 1.5em;
	font-weight: 700;

	& a {
		text-decoration: none;
		color: inherit;
	}

	& a::after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
`;

const PostListExcerpt = styled.p`
	margin-top: auto;
	font-size: var(--size-400);
`;

const PostListMeta = styled.div`
	margin-top: 2rem;

	font-size: var(--size-300);
	display: flex;
	justify-content: space-between;
`;
